<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-row class="" :gutter="20" type="flex">
			  <el-col :span="24">
				<el-card class="mt-2">
					学期：
					<el-select placeholder="请选择学期" size="medium" v-model="s_id" @change="changeSemester" style="width: 200px;margin-right: 30px;">
						<el-option :value="item.id" :label="item.year + item.tag" v-for="(item,index) in semester_list" :key="item.id"/>
					</el-select>
					<el-tooltip class="item" effect="dark" content="下载" placement="top">
					  <el-button type="primary" icon="el-icon-download" size="mini" @click="download">下载</el-button>		
					</el-tooltip>
				</el-card>
				<el-card class="mt-2">
				  <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">基本信息</h4>
				  <div class="d-flex align-items-center flex-wrap">
					  <div class="w-100 flex align-center mb-2">
					  	 <div class="flex-1 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								姓名：
							</div>
							<div class="flex-1">
								{{ info.username || '' }}
							</div>
					  	 </div>
					  	 <div class="flex-1 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								性别：
							</div>
							<div class="flex-1">
								 <el-tag v-if="info.gender === 1" size="mini" type="primary">男</el-tag>
								 <el-tag v-if="info.gender === 2" size="mini" type="warning">女</el-tag>
							</div>
					  	 </div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
                          <div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								年龄：
							</div>
							<div class="flex-1">
								 {{ info.age || '' }}
							</div>
                          </div>
                          <div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								民族：
							</div>
							<div class="flex-1">
								 {{ info.nation || '' }}
							</div>
                          </div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						  <div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								学历：
							</div>
							<div class="flex-1">
								 {{ info.xl || '' }}
							</div>
						  </div>
						  <div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								学科：
							</div>
							<div class="flex-1">
								 {{ info.xuele_txt || '' }}
							</div>
						  </div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								编码：
							</div>
							<div class="flex-1">
								 {{ info.code || '' }}
							</div>
						</div>
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								职称：
							</div>
							<div class="flex-1">
								 {{ info.zc || '' }}
							</div>
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								手机号：
							</div>
							<div class="flex-1">
								 {{ info.mobile || '' }}
							</div>
						</div>
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								毕业院校：
							</div>
							<div class="flex-1">
								 {{ info.by_school || '' }}
							</div>
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								出生日期：
							</div>
							<div class="flex-1">
								 {{ info.birthday || '' }}
							</div>
						</div>
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 80px;">
								从业时间：
							</div>
							<div class="flex-1">
								 {{ info.work_date || '' }}
							</div>
						</div>
					  </div>
				  </div>
				</el-card>
				
				<el-card class="mt-2">
				  <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">考核汇总</h4>
				  <div class="d-flex align-items-center flex-wrap">
					  <div class="w-100 flex align-center mb-2">
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 100px;">
								代课量：
							</div>
							<div class="flex-1">
								 {{ info.dkl_score || '0' }}
							</div>
						</div>
						<div class="w-100 flex align-center">
							<div class="flex align-center opacity-60" style="width: 100px;">
								教学常规：
							</div>
							<div class="flex-1">
								 {{ info.jxcg_score || '0' }}
							</div>
						</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									集体备课：
								</div>
								<div class="flex-1">
									 {{ info.jtbk_score || '0' }}
								</div>
							</div>
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									教研活动：
								</div>
								<div class="flex-1">
									 {{ info.jyhd_score || '0' }}
								</div>
							</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									活动指导：
								</div>
								<div class="flex-1">
									 {{ info.hdzd_score || '0' }}
								</div>
							</div>
							<div class="w-100 flex align-center" v-if="info.xueke <=9">
								<div class="flex align-center opacity-60" style="width: 100px;">
									教学成绩：
								</div>
								<div class="flex-1">
									 {{ info.jxcj_score || '0' }}
								</div>
							</div>
							<div class="w-100 flex align-center" v-else>
								<div class="flex align-center opacity-60" style="width: 100px;">
									竞赛成绩：
								</div>
								<div class="flex-1">
									 {{ info.jscj_score || '0' }}
								</div>
							</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									教师荣誉：
								</div>
								<div class="flex-1">
									 {{ info.jsry_score || '0' }}
								</div>
							</div>
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									兼职绩效：
								</div>
								<div class="flex-1">
									 {{ info.jzjx_score || '0' }}
								</div>
							</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									对外监考：
								</div>
								<div class="flex-1">
									 {{ info.dwjk_score || '0' }}
								</div>
							</div>
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									纪律处罚：
								</div>
								<div class="flex-1">
									 {{ info.jlcf_score || '0' }}
								</div>
							</div>
					  </div>
					  <div class="w-100 flex align-center mb-2">
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									非高考科目：
								</div>
								<div class="flex-1">
									 {{ info.ungk_score || '0' }}
								</div>
							</div>
							<div class="w-100 flex align-center">
								<div class="flex align-center opacity-60" style="width: 100px;">
									总分：
								</div>
								<div class="flex-1">
									 {{ info.total || '0' }}
								</div>
							</div>
					  </div>
				  </div>
				</el-card>
				
				<el-card class="mt-2">
				  <h4 class="fs_18 font-weight-semibold m-0 text-000 mb-3">荣誉获奖</h4>
				  <div class="d-flex align-items-center flex-wrap">
					  <div class="w-100 flex flex-column mb-2" v-if="info.ry_list && info.ry_list.length > 0">
					  	<div class="w-100 flex align-center opacity-60 mb-2" style="width: 100px;">
					  		获得荣誉：
					  	</div>
					  	<div class="w-100 flex align-center pl-5 bx" v-for="(item,index) in info.ry_list" :key="index">
					  		 {{item.title || ''}}
					  	</div>
					  </div>
					  <div class="w-100 flex flex-column mb-2" v-if="info.jl_list && info.jl_list.length > 0">
					  	<div class="w-100 flex align-center opacity-60 mb-2" style="width: 100px;">
					  		获得奖励：
					  	</div>
					  	<div class="w-100 flex align-center pl-5 bx" v-for="(item,index) in info.jl_list" :key="index">
					  		{{item.title || ''}}
					  	</div>
					  </div>
				  </div>
				</el-card>
				
			  </el-col>
		</el-row>
	</div>
</template>

<script>
import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	import dayjs from 'dayjs'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'teacher',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				id:'',
				info:{},
				s_id:'',
				year:'',
				semester_list:[],
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			this.getsemester()
			this.getData()
		},
		computed:{
			
		},
		methods:{
			changeSemester(e){
				this.s_id = e
				this.getData()
				this.getKhResultAsc()
			},
			getsemester(){
				this.axios.get('/manage/semester/all').then(res=>{
					if(res.status){
						this.semester_list = res.data
						if(res.data.length > 0){
							this.year = res.data[0].year + ' ' + res.data[0].tag
							this.s_id = res.data[0].id
							this.getData()
						}
					}
				})
			},
			getData(){
				this.axios.get('/manage/'+this.preUrl+'/detail',{
					params:{
						id:this.id,
						s_id:this.s_id
					}
				}).then(res=>{
					if(res.status){
						this.info = res.data
					}
				})
			},
			download(){
				this.axios.get('/manage/'+this.preUrl+'/downloadWord',{
					responseType: 'arraybuffer',
					params:{
						id:this.id,
						s_id:this.s_id
					}
				}).then(res=>{
					console.log(res)
					  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-word" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = this.info.username+'-'+dayjsTime+'.docx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
		},
    }
</script>

<style>
</style>